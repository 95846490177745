<template>
  <Survey :survey="survey" />
</template>

<script>
// ...
const SURVEY_ID = 1;

export default {
  // ...
  data() {
    const survey = new Model(surveyJson);
    survey.onComplete.add(this.surveyComplete);

    return {
      survey
    }
  },
  methods: {
    surveyComplete (sender) {
      saveSurveyResults(
        "https://your-web-service.com/" + SURVEY_ID,
        sender.data
      )
    }
  },
}

function saveSurveyResults(url, json) {
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(json)
  })
  .then(response => {
    if (response.ok) {
      // Handle success
    } else {
      // Handle error
    }
  })
  .catch(error => {
    // Handle error
  });
}
</script>